import React, { Component } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

const Nav = () => {
  let history = useHistory();

  const apolloClient = useApolloClient();

  const handleLogOut = () => {
    localStorage.removeItem("AUTH_TOKEN"); //we clear the authToken
    const logout = async () => {
      await apolloClient.clearStore(); // we remove all information in the store
    };
    history.push("/");
    window.location.reload();
    return logout;
  };

  return (
    <NavContainer>
      <Link to="/">
        <Logo src="https://www.ponymusic.com.au/assets/website_logo.png"></Logo>
      </Link>
      <NavElementContainer>
        {localStorage.getItem("AUTH_TOKEN") ? (
          <NavElement to="/users">users</NavElement>
        ) : (
          false
        )}
        {localStorage.getItem("AUTH_TOKEN") ? (
          <NavElement to="/" onClick={handleLogOut}>
            sign out
          </NavElement>
        ) : (
          <NavElement to="/signin">sign in</NavElement>
        )}
      </NavElementContainer>
    </NavContainer>
  );
};

export default Nav;
//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsIm5hbWUiOiJzYW1lZXIiLCJpYXQiOjE1OTk1NzE5MjMuOTY2LCJpc3MiOiJodHRwczovL215YXBwLmNvbS8iLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsidXNlciJdLCJ4LWhhc3VyYS11c2VyLWlkIjoxLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJ1c2VyIiwieC1oYXN1cmEtcm9sZSI6InVzZXIifSwiZXhwIjoxNTk5NjU4MzIzfQ.mMK9f55Ao38-RIAUPEosKz6AmNki7aML2hbC6UVYsZM

const NavContainer = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  height: 6rem;
  display: flex;
  box-shadow: 0px 0px 8px 2px #0000001f;
  padding-top: 2rem;
`;

const NavElementContainer = styled.div`
  margin-left: auto;
`;

const NavElement = styled(Link)`
  font-size: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  color: black;
`;

const Logo = styled.img`
  margin-top: 3px;
  width: 13rem;
`;

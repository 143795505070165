import React from "react";
import "./App.css";
import Nav from "../nav/Navbar";
import AppointmentCalendar from "../appointments/home";
import SignInForm from "../SignIn/SignInForm";
import UsersTable from "../Users/UsersTable";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { ApolloLink } from "apollo-link";

import gql from "graphql-tag";
import { ApolloProvider, Mutation, Subscription } from "react-apollo";

function App() {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HTTP_END_POINT,
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_END_POINT,
    options: {
      reconnect: true,
      connectionParams: {},
    },
  });

  const authMiddleware = (authToken) =>
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      if (authToken) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
      }

      return forward(operation);
    });

  const authToken = localStorage.getItem("AUTH_TOKEN");

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    authMiddleware(authToken).concat(httpLink),
    wsLink
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
  console.log(process.env);
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <Nav />
          <Switch>
            <Route path="/users" component={UsersTable} />
            <Route path="/signin" component={SignInForm} />
            <Route path="/" component={AppointmentCalendar} />
          </Switch>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;

import React, { Component, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./home.scss";
import swal from "@sweetalert/with-react";
import "../Forms/loginForm.scss";
import "../Forms/newBooking.scss";
import "../Forms/editBooking.scss";

const localizer = momentLocalizer(moment);

class AppointmentCalendar extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();

    const events = [
      {
        id: 0,
        title: "Syed's Booking - Confirmed",
        allday: true,
        start: new Date(new Date().setHours(new Date().getHours() - 4)),
        end: new Date(new Date().setHours(new Date().getHours() + 4)),
        status: "5CB85C",
      },
      {
        id: 1,
        title: "Sameer's Booking - Confirmed",
        allday: true,
        start: new Date(2020, 8, 20, 19, 30, 0),
        end: new Date(2020, 8, 23, 2, 0, 0),
        status: "5CB85C",
      },
      {
        id: 4,
        title: "Matt's Booking - Cancelled",
        allday: true,
        start: new Date(2020, 8, 14, 19, 30, 0),
        end: new Date(2020, 8, 17, 2, 0, 0),
        status: "C9302C",
      },
      {
        id: 2,
        title: "Damien's Booking - Confirmed",
        allday: true,
        start: new Date(2020, 8, 27, 19, 30, 0),
        end: new Date(2020, 8, 29, 2, 0, 0),
        status: "5CB85C",
      },
      {
        id: 3,
        title: "Damien's Booking - Pending Approval",
        allday: true,
        start: new Date(2020, 8, 1, 19, 30, 0),
        end: new Date(2020, 8, 3, 2, 0, 0),
        status: "F0AD4E",
      },
    ];
    this.state = {
      name: "React",
      events,
    };
  }
  loginformAlert() {
    swal({
      title: "Recording Studio Booking System Login",
      buttons: {
        cancel: "Forgot Password",
        confirm: "Login",
      },
      content: (
        <div className="alert-container">
          <div className="content">
            <div className="image">
              <img src="https://www.ponymusic.com.au/assets/website_logo.png" />
            </div>
            <form className="form">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Please enter your username"
                ></input>
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="password-input"
                  type="password"
                  name="password"
                  placeholder="Please enter your password"
                ></input>
                <p>
                  If you do not have an account and would like to make a
                  Recording Studio Booking, please contact The Studio Manager
                </p>
              </div>
            </form>
          </div>
        </div>
      ),
    });
  }

  editBookingForm() {
    swal({
      button: false,
      content: (
        <div className="main-container">
          <div className="row1">Edit Booking</div>
          <div className="row2">Client Details</div>
          <div className="row3">
            <label htmlFor="firstName">First</label>
            <input type="text" name="firstName" id="firstName"></input>
            <label htmlFor="firstName">Last</label>
            <input type="text" name="lastName" id="lastName"></input>
          </div>
          <div className="row4">
            <label htmlFor="phone">Phone</label>
            <input type="phone" name="phone" id="phone"></input>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email"></input>
          </div>
          <div className="row2">Booking Information</div>
          <div className="row3">
            <label htmlFor="phone">Start Date</label>
            <input type="date" name="startDate" id="startDate"></input>
            <label htmlFor="endDate">End Date</label>
            <input type="date" name="endDate" id="endDate"></input>
          </div>
          <div className="row3">
            <label htmlFor="phone">Display Name</label>
            <input type="text" name="displayName" id="displayName"></input>
            <label htmlFor="brandName">Brand Name</label>
            <input type="text" name="brandName" id="brandName"></input>
          </div>
          <div className="row1">Admin Only Notes</div>
          <div className="row5">
            <textarea type="text" name="adminNotes" id="adminNotes"></textarea>
          </div>

          <div className="row1">Comments</div>
          <div className="row3">
            <button>Edit</button>
            <button>Delete</button>
            <textarea type="text" name="comments" id="comments"></textarea>
          </div>
          <div className="row3">
            <textarea type="text" name="comments2" id="comments2"></textarea>
            <button>Edit</button>
            <button>Delete</button>
          </div>
          <div className="footer-buttons">
            <button>Confirm Booking</button>
            <button>Cancel Booking</button>
          </div>
          <div className="footer-buttons">
            <button className="requestButton">Request</button>
            <button>Delete Booking</button>
          </div>
          <div className="footer">
            <button>Cancel</button>
          </div>
        </div>
      ),
    });
  }

  eventStyleGetter(event) {
    var backgroundColor = "#" + event.status;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "4px",
      height: "30px",
      opacity: 1,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  }

  render() {
    return (
      <div className="base-container">
        <button>Make Booking</button>
        <div className="Calendar-container">
          <Calendar
            selectable
            events={this.state.events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            localizer={localizer}
            views={{
              month: true,
            }}
            onSelectSlot={this.loginformAlert}
            onSelectEvent={this.editBookingForm}
            eventPropGetter={this.eventStyleGetter}
            // onSelectSlot={(slotinfo) => this.loginformAlert(slotinfo)}
            // dayPropGetter={this.loginformAlert}
          />
        </div>
      </div>
    );
  }
}
export default AppointmentCalendar;

import React, { Component, useState } from 'react';
import styled from '@emotion/styled'
import { ApolloProvider, Mutation, Subscription } from 'react-apollo'
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { InMemoryCache } from 'apollo-cache-inmemory'
import { useApolloClient } from "@apollo/react-hooks";



const SignInForm = (props) =>{
    const SIGNIN_MUTATION = gql`
    mutation login($username:String!,$password:String!){
        login(password: $password, username: $username) {
            email
            id
            name
            accessToken
        }
    }`

    const handleSubmit = (e) => {
        e.preventDefault()
        signInMutation({ variables: { username: email, password:password } });
    }

    const handleLoginSuccess = (data) => {
        localStorage.setItem('EMAIL', data.login.email)
        localStorage.setItem('ID', data.login.id)
        localStorage.setItem('NAME', data.login.name)
        localStorage.setItem('AUTH_TOKEN', data.login.accessToken)
        window.location.reload()
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [ signInMutation, {data, loading, error} ] = useMutation(SIGNIN_MUTATION, {onError: (error)=>{}});
    console.log(useApolloClient())
    if (loading) return <p>Loading...</p>;
    if (data){handleLoginSuccess(data)}
    console.log(data)
    return (
        <ApolloProvider>
            {localStorage.getItem('AUTH_TOKEN') ? <p>Welcome, {localStorage.getItem('NAME')}</p>:
        <form onSubmit={handleSubmit}>
            <p>email:</p> <input onChange={(e)=>{setEmail(e.target.value)}} placeholder='example@gmail.com'></input>
            <p>password:</p> <input type="password" onChange={(e)=>{setPassword(e.target.value)}} placeholder='8+ characters'></input>
            {error ? <p style={{color:'red'}}>{error.message.substring(15)}</p> : <p></p> }
            <button>submit</button>
        </form>}
      </ApolloProvider>
    )




}

export default SignInForm
import React, { Component, useState } from "react";
import styled from "@emotion/styled";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/react-hooks";
import { ApolloProvider, Mutation, Subscription } from "react-apollo";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    margin: auto;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, renderRowSubComponent }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useExpanded
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 100);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment {...row.getRowProps()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                    Inside it, call our renderRowSubComponent function. In reality,
                    you could pass whatever you want as props to
                    a component like this, including the entire
                    table instance. But for this example, we'll just
                    pass the row
                  */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function App() {
  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? "↓" : "→"}
          </span>
        ),
      },
      {
        Header: "User",
        columns: [
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ row }) => <p>{Date(row.values.created_at).toString()}</p>,
          },
        ],
      },
    ],
    []
  );



  const USERS_SUBSCRIPTION = gql`
    query MySubscription {
      users {
        email
        id
        name
        created_at
      }
    }
  `;

  const SIGNUP_MUTATION = gql`
    mutation signup($name: String!, $email: String!, $password: String!) {
      signup(email: $email, name: $name, password: $password) {
        id
      }
    }
  `;

  const UPDATE_USER_MUTATION = gql`
    mutation updateUser(
      $name: String!
      $email: String!
      $hashed_password: String!
      $id: String!
    ) {
      update_users_by_pk(
        pk_columns: { id: $id }
        _set: { email: $email, name: $name, hashed_password: $hashed_password }
      ) {
        email
        id
        name
        created_at
      }
    }
  `;

  const DELETE_USER_MUTATION = gql`
    mutation deleteUser($id: Int!) {
      delete_users(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `;

  const handleSubmit = (e) => {
    e.preventDefault();
    signUpMutation({
      variables: { email: email, password: password, name: name },
    });
  };



  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [newEmail, setNewEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [editUserId, setEditUserId] = useState("");


    const renderRowSubComponent = React.useCallback(
      ({ row }) => (
        <>
          <div style={{ display: "flex" }}>
            <p>user role:</p>
            <select name="role" id="cars">
              <option value="volvo">admin</option>
              <option value="saab">staff</option>
              <option value="mercedes">user</option>
            </select>
          </div>
          <div style={{ display: "flex" }}>
            <p>email: </p>
            <input 
           onChange={(e) => {
            setNewEmail(e.target.value);
          }}
              ></input>
          </div>
          <div style={{ display: "flex" }}>
            <p>password: </p>
            <input></input>
          </div>
          <div style={{ display: "flex" }}>
            <button onClick={() => {handleSave()}}>Save Changes</button>
            <button
              onClick={() => {
                deleteUserMutation({ variables: { id: row.values.id } });
              }}
            >
              delete user
            </button>
          </div>
        </>
      ),
      []
    );

    const handleSave = () => {
      console.log('save', newEmail, newName, newPassword, editUserId)
  
    };

  const [signUpMutation, { data: signUpData, loading, error }] = useMutation(
    SIGNUP_MUTATION,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const [deleteUserMutation, { data: deleteUserData }] = useMutation(
    DELETE_USER_MUTATION,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const AllUsers = () => {
    const { data: usersData, loading } = useQuery(USERS_SUBSCRIPTION);
    return usersData;
  };

  let data = [];
  let usersData = AllUsers();
  if (usersData ? (data = usersData.users) : false)
    if (loading) return <p>Loading...</p>;

  return (
    <ApolloProvider>
      <Styles>
        <form onSubmit={handleSubmit}>
          <p>name:</p>{" "}
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Mike Jhonson"
          ></input>
          <p>email:</p>{" "}
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="example@gmail.com"
          ></input>
          <p>password:</p>{" "}
          <input
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="8+ characters"
          ></input>
          <p></p>
          <button>Add User</button>
        </form>
        <Table
          columns={columns}
          data={data}
          renderRowSubComponent={renderRowSubComponent}
        />
      </Styles>
    </ApolloProvider>
  );
}

export default App;
